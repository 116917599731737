<template>
  <section>
    <div class="columns">
      <div class="column is-full">
        <el-card class="box-card" shadow="never">
          <div slot="header" class="clearfix">
            <span><i class="fa fa fa-book"> 主题 / 更新主题</i></span>
          </div>
          <div>
            <el-form :model="topic" ref="topic" class="demo-topic">
              <el-form-item prop="title">
                <el-input
                  v-model="topic.title"
                  placeholder="输入新的主题名称"
                ></el-input>
              </el-form-item>

              <!--Markdown-->
              <div id="vditor"></div>

              <b-taginput
                v-model="tags"
                class="my-3"
                maxlength="15"
                maxtags="3"
                ellipsis
                placeholder="请输入主题标签，限制为 15 个字符和 3 个标签"
              />
              <el-form-item class="mt-3">
                <el-button type="primary" @click="handleUpdate()"
                  >更新
                </el-button>
                <el-button @click="resetForm('topic')">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </div>
    </div>
  </section>
</template>

<script>
import { getTopic, update } from "@/api/post";
import Vditor from "vditor";
import "vditor/dist/index.css";

export default {
  name: "TopicEdit",
  components: {},
  data() {
    return {
      topic: {},
      tags: [],
    };
  },
  created() {
    this.fetchTopic();
  },
  methods: {
    renderMarkdown(md) {
      this.contentEditor = new Vditor("vditor", {
        height: window.innerHeight / 2,
        placeholder: "输入要更新的内容",
        // height: "auto",
        theme: 'classic',
        counter: {
          enable: true,
          type: 'markdown'
        },
        preview: {
          delay: 0,
          hljs: {
            style: 'monokai',
            lineNumber: true
          }
        },
        tab: '\t',
        /*hint: {
          delay: 200,
          emoji: {
            "+1": "👍",
            "-1": "👎",
            "confused": "😕",
            "eyes": "👀️",
            "heart": "❤️",
            "rocket": "🚀️",
            "smile": "😄",
            "tada": "🎉️",
          },
          // emojiPath: `https://unpkg.com/vditor@${VDITOR_VERSION}/dist/images/emoji`,
          extend: [],
          parse: true,
        },*/
        typewriterMode: true,
        toolbar: [
          "emoji",
          "headings",
          "bold",
          "italic",
          "strike",
          "|",
          "list",
          "ordered-list",
          "check",
          "outdent",
          "indent",
          "|",
          "quote",
          "line",
          "code",
          "inline-code",
          // "insert-before",
          // "insert-after",
          "|",
          "upload",
          "record",
          "table",
          "|",
          "undo",
          "redo",
          // "|",
          // "fullscreen",
          // "edit-mode",
          // {
          //   name: "more",
          //   toolbar: [
          //     "both",
          //     "code-theme",
          //     "content-theme",
          //     "export",
          //     "outline",
          //     "preview",
          //     "devtools",
          //     "info",
          //     "help",
          //   ],
          // },
        ],
        toolbarConfig: {
          pin: false,
          hide:false
        },
        cache: {
          enable: false
        },
        mode: 'ir',
        after: () => {
          this.contentEditor.setValue(md);
        },
        //这里写上传
        upload: {
          accept: 'image/jpg, image/jpeg, image/png, image/gif, .mp4',//规定上传的图片格式
          url: process.env.VUE_APP_SERVER_URL + "/post/uploadMdPic",//图片或文件上传
          multiple: false,
          fieldName: 'file',
          max: 10 * 1024 * 1024,//上传图片或视频的大小
          // extraData: { 'access_token': this.token }, //为 FormData 添加额外的参数
          // linkToImgUrl: "/api/admin/uploadFile?type=99",外链图片本地化
          filename(name) {
            return name.replace(/[^(a-zA-Z0-9\u4e00-\u9fa5\.)]/g, "")
                .replace(/[\?\\/:|<>\*\[\]\(\)\$%\{\}@~]/g, "")
                .replace("/\\s/g", "");
          },
          // linkToImgFormat(files) {
          //   let code = 0
          //   let msg = ''
          //   let data = JSON.parse(files)
          //   // //上传图片请求状态
          //   if (data.success === 1) {
          //     let responseData = data.result.path
          //     let succUrl = {}
          //     let originalURL = 'originalURL'
          //     let url = 'url'
          //     succUrl[originalURL] = data.result.originalURL
          //     succUrl[url] = responseData
          //     let end = JSON.stringify({
          //       msg,
          //       code,
          //       data: succUrl
          //     })
          //     return end
          //   }
          //   setTimeout(() => {
          //     self.outLinkImgFun();
          //   }, 3000);
          // },
          format(files, responseText) {
            // let imageResult = JSON.parse(responseText)
            let code = JSON.parse(responseText)
            let msg = JSON.parse(responseText)
            let data = JSON.parse(responseText)
            let filName = data.url
            //上传文件请求状态
            if (data.code === 200) {
              let lastTipNum = filName.substr(filName.lastIndexOf('/', filName.lastIndexOf('/') - 1) + 1);
              let index = lastTipNum.lastIndexOf("\/");
              self.imgNameStr = decodeURI(lastTipNum.substring(index + 1, lastTipNum.length));
              // let responseData = self.gb.imgPath(data.result.image_url)
              let responseData = data.url
              let succ = {}
              succ[self.imgNameStr] = responseData
              //文件回显
              return JSON.stringify({
                msg,
                code,
                data: {
                  errFiles: [],
                  succMap: succ
                }
              })
            }
          },
          error(msg) {
            console.log(msg + "上传失败了")
          }
        }
      });
    },
    fetchTopic() {
      getTopic(this.$route.params.id).then((value) => {
        this.topic = value.data.topic;
        this.tags = value.data.tags.map(tag => tag.name);
        this.renderMarkdown(this.topic.content);
      });
    },
    handleUpdate: function () {
      this.topic.content = this.contentEditor.getValue();
      update(this.topic).then((response) => {
        const { data } = response;
        console.log(data);
        setTimeout(() => {
          this.$router.push({
            name: "post-detail",
            params: { id: data.id },
          });
        }, 800);
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.contentEditor.setValue("");
      this.tags = "";
    },
  },
};
</script>

<style>
.vditor-reset pre > code {
  font-size: 100%;
}
</style>
